import React from 'react';
import Footer from '../../componentes/rodape';
import { Helmet } from 'react-helmet';


function Tratamentos() {
    return (
        <>

            <Helmet>
                <title>Dra Brunele Drumond - Tratamentos: Doenças de Pele | Cosmiatria e Estética | Tricologia Médica </title>

                <meta name="description" content="Dra Brunele Drumond - Marque sua Consulta -  Tratamentos Clínico, Estético e Capilar: Toxina botulínica. Tricologia. Ultraformer 3. Harmonização facial. Prenchimento Labial. Bioestimuladores | Consulte e Trate Acne. Rugas e Linhas de Expressão. Tratamentos de Manchas de Pele. " />
                <meta name="keywords" content="dermatologia, Toxina botulínica, ultraformer 3, preenchimento labial, preenchimento, tricologia, harmonização facial, bioestimuladores" />
            </Helmet>
            <header className="site-navbar site-navbar-target" role="banner">
                <div className="header-area">
                    <div id="sticky-header" className="main-header-area">
                        <div className="container">
                            <div className="row align-items-center position-relative">

                                <div className="col-3">
                                    <div className="site-logo">
                                        <a href="/"><img src="./images/Brunele.ico" alt="dermatologista" /></a>
                                    </div>
                                </div>

                                <div className="col-9 text-right main-menu">

                                    <span className="d-inline-block d-lg-none"><a href="#" className=" site-menu-toggle js-menu-toggle py-5">
                                        <span className="icon-menu text-white"></span></a></span>

                                    <nav className="site-navigation position-relative text-right" role="navigation">

                                        <ul className="site-menu js-clone-nav mr-auto d-none d-lg-block">
                                            <li className=""><a href="https://www.drabruneledrumond.com.br"><span>Home</span></a></li>
                                            <li className='active'><a href="https://www.drabruneledrumond.com.br/tratamentos"><span>Tratamentos</span></a></li>
                                            <li className=''><a href="https://www.drabruneledrumond.com.br/sobre"><span>Sobre</span></a></li>
                                            <li className=''><a href="https://www.drabruneledrumond.com.br/contato"><span>Contato</span></a></li>
                                        </ul>
                                    </nav>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>

            </header>

            <div className="site-blocks-cover top1 overlay" data-aos="fade" data-stellar-background-ratio="0.5">
                <div className="container">
                    <div className="row align-items-center justify-content-center text-center">

                        <div className="col-md-10">
                            <span id='aparece' >tratamentos</span>
                            <div className="row justify-content-center mb-4">
                                <div className="col-md-10 text-center">
                                    <h1 data-aos="fade-up" className="mb-5">Nossos  <span className="typed-words"></span></h1>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>








            <div className="site-section">
                <div className="container">
                    <div className="row">

                        <div className="col-md-8">

                            <div className="row mb-3 align-items-stretch">
                                <div className="col-md-6 col-lg-6 mb-4 mb-lg-4">
                                    <div className="h-entry">
                                        <img src="images/harmo.webp" alt="harmonização facial" className="img-fluid" />
                                        <div className="h-entry-inner">
                                            <h2 className="font-size-regular">Harmonização facial</h2>

                                            <p>Este tratamento é utilizado para harmonizar, embelezar, ressaltar atributos e tratar assimetrias. Entenda: harmonizar é deixar suave, belo e natural, diferente de muitos rostos que temos visto por aí.

                                                Não tem receita de bolo e não vale deixar todo mundo igual ok? Em alguns pontos estratégicos do rosto, que tendo necessidade de abordar, obtemos uma suavidade para a expressão facial de maneira global. </p>
                                            <br /><br />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-6 mb-4 mb-lg-4">
                                    <div className="h-entry">
                                        <img src="images/fototric.webp" alt="tratamento do couro cabeludo" className="img-fluid" />
                                        <div className="h-entry-inner">
                                            <h2 className="font-size-regular">Tricologia</h2>

                                            <p>O cabelo é um dos elementos mais importantes da aparência de uma pessoa. Do grego “Thricos” – cabelos e “logos” – estudo, definem a Tricologia como área da ciência que se dedica a solucionar as doenças, condições e os problemas relacionados aos cabelos e couro cabeludo.
                                                No Brasil, assim como em outros países, é nítida a crescente a preocupação dos pacientes em relação a problemas nos seus cabelos,  pelo fato impactante  na qualidade de vida do indivíduo que sofre com perdas capilares.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-6 mb-4 mb-lg-4">
                                    <div className="h-entry">
                                        <img src="images/boto.webp" alt="Toxina botulínica" className="img-fluid" />
                                        <div className="h-entry-inner">
                                            <h2 className="font-size-regular">Toxina botulínica</h2>

                                            <p> A Toxina botulínica é realizada há décadas com objetivo de suavizar as linhas de expressão de peles mais maduras, mas atualmente é cada vez mais comum como forma de prevenção. </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-6 mb-4 mb-lg-4">
                                    <div className="h-entry">
                                        <img src="images/pele.webp" alt="dermatologista" className="img-fluid" />
                                        <div className="h-entry-inner">
                                            <h2 className="font-size-regular">Dermatologia</h2>

                                            <p>Ter uma pele saudável e bem cuidada é fonte de autoestima e bem-estar emocional, bem como, reduz significativamente os riscos de desenvolver problemas mais sérios. </p><br/>
                                        
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-6 mb-4 mb-lg-4">
                                    <div className="h-entry">
                                        <img src="images/preeenchimento.webp" alt="preenchimento labial" className="img-fluid" />
                                        <div className="h-entry-inner">
                                            <h2 className="font-size-regular">Preenchimentos</h2>

                                            <p>O preenchimento labial é um procedimento que tem como objetivo delinear, ou realçar o contorno da boca e/ou dar volume aos lábios. É realizado através da aplicação do ácido hialurônico, que é seguro e maleável, uma vez que já o produzimos naturalmente em nosso corpo.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-6 mb-4 mb-lg-4">
                                    <div className="h-entry">
                                        <img src="images/ult.webp" alt="ultraformer 3" className="img-fluid" />
                                        <div className="h-entry-inner">
                                            <h2 className="font-size-regular">Ultraformer III</h2>

                                            <p>O Ultraformer III é uma tecnologia não invasiva que faz parte de uma nova geração de ultrassom de lifting, desempenhando diversos procedimentos estéticos com um único aparelho.</p>
                                            <br /><br /><br />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-6 mb-4 mb-lg-4">
                                    <div className="h-entry">
                                        <img src="images/close.webp" alt="bioestimuladores" className="img-fluid" />
                                        <div className="h-entry-inner">
                                            <h2 className="font-size-regular">Bioestimuladores</h2>

                                            <p>Os bioestimuladores de colágeno são produtos compostos por substâncias capazes de estimular as nossas células da pele, produtoras de colágeno, que já estão lentas e adormecidas com a idade. São aplicados na face, pescoço, colo, abdome, glúteos, mãos - em todas as áreas necessitadas de estímulo para a produção de novas fibras de colágeno. Com a aplicação do bioestimulador, a pele retoma a produção das fibras de elastina e do próprio colágeno, auxiliando na sustentação da face e em uma consequente aparência mais jovem.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className="col-md-3 ml-auto">


                            <div className="mb-5">
                                <h3 className="h5 text-black mb-3">Cosmiatria e estética</h3>
                                <ul className="list-unstyled post-lists">
                                    <li className="mb-2"><a className='pointerevents' href="https://www.drabruneledrumond.com.br/tratamentos">Já a dermatologia estética se dedica a cuidar de prejuízos estéticos que podem acometer os pacientes. Cuidando, em primeiro lugar, da beleza da pele, cabelos e unhas. Assim, com tecnologia avançada, conseguimos prevenir o envelhecimento cutâneo, melhorando e elevando a autoestima.</a></li>
                                    
                                </ul>
                            </div>
                            <div className="mb-5">
                                <h3 className="h5 text-black mb-3">Doenças de pele</h3>
                                <ul className="list-unstyled post-lists">
                                    <li className="mb-2"><a className='pointerevents' href="https://www.drabruneledrumond.com.br/tratamentos">O profissional especializado realiza diagnósticos e previne, bem como indica, os melhores tratamentos - para, então, garantir ao paciente o bem-estar e uma vida saudável. Os focos principais são doenças que atrapalham a rotina como, por exemplo, a acne. Além disso, diversos tipos de alergias da pele, da parte capilar, foliculite, câncer de pele, herpes, hiperidrose, machas, entre outros.</a> </li>
                                    
                                </ul>
                            </div>

                            <div className="mb-5">
                                <h3 className="h5 text-black mb-3">Tricologia médica</h3>
                                <ul className="list-unstyled post-lists">
                                    <li className="mb-2"><a className='pointerevents' href="https://www.drabruneledrumond.com.br/tratamentos">Consulta e tratamentos de couro cabeludo e Capilar</a></li>
                                    
                                </ul>
                            </div>

                        </div>



                    </div>
                </div>
            </div>
            <Footer />


        </>
    )
}
export default Tratamentos;
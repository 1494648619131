import React from 'react';

import Footer from '../../componentes/rodape';
import { Helmet } from 'react-helmet';

function Contato() {

    return (

        <>
            <Helmet>
                <title>Dra Brunele Drumond - Contato | Clínica La Vita Itaúna - R. João Gonçalves, 71 Centro</title>

                <meta name="description" content="Dra Brunele Drumond | Marque sua Consulta - Clínica La Vita Itaúna - R. João Gonçalves, 71 Centro | Telefone: (37)3242-3690 | Whatsapp: (37)9871-2618" />
                <meta name="keywords" content="clínica la vita, itaúna, dermatologia, Toxina botulínica, ultraformer 3, preenchimento labial, preenchimento, tricologia, harmonização facial" />
            </Helmet>


            <header className="site-navbar site-navbar-target" role="banner">
                <div className="header-area">
                    <div id="sticky-header" className="main-header-area">
                        <div className="container">
                            <div className="row align-items-center position-relative">

                                <div className="col-3">
                                    <div className="site-logo">
                                        <a href="/"><img src="./images/Brunele.ico" alt="" /></a>
                                    </div>
                                </div>

                                <div className="col-9 text-right main-menu">

                                    <span className="d-inline-block d-lg-none"><a href="#" className=" site-menu-toggle js-menu-toggle py-5">
                                        <span className="icon-menu text-white"></span></a></span>

                                    <nav className="site-navigation position-relative text-right" role="navigation">

                                        <ul className="site-menu js-clone-nav mr-auto d-none d-lg-block">
                                            <li className=""><a href="https://www.drabruneledrumond.com.br"><span>Home</span></a></li>
                                            <li className=''><a href="https://www.drabruneledrumond.com.br/tratamentos"><span>Tratamentos</span></a></li>
                                            <li className=''><a href="https://www.drabruneledrumond.com.br/sobre"><span>Sobre</span></a></li>
                                            <li className='active'><a href="https://www.drabruneledrumond.com.br/contato"><span>Contato</span></a></li>
                                        </ul>
                                    </nav>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>

            </header>
            <div className="site-blocks-cover top1 overlay" data-aos="fade" data-stellar-background-ratio="0.5">
                <div className="container">
                    <div className="row align-items-center justify-content-center text-center">

                        <div className="col-md-10">
                            <span id='aparece' >uma consulta</span>

                            <div className="row justify-content-center mb-4">
                                <div className="col-md-10 text-center">
                                    <h1 data-aos="fade-up" className="mb-5">Agende  <span className="typed-words"></span></h1>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className="site-section bg-light">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7 mb-5" data-aos="fade">



                            <form action="contact.php" method="post" className="p-5 bg-white sobe-anima" >


                                <div className="row form-group">
                                    <div className="col-md-12">
                                        <label className="text-black" htmlFor="fname">Nome</label>
                                        <input type="text" name="name" id="name" className="form-control" />
                                    </div>

                                </div>

                                <div className="row form-group">

                                    <div className="col-md-12">
                                        <label className="text-black" htmlFor="email">Email</label>
                                        <input type="email" name='email' id="email" className="form-control" />
                                    </div>
                                </div>

                                <div className="row form-group">

                                    <div className="col-md-12">
                                        <label className="text-black" htmlFor="subject">Assunto</label>
                                        <input type="subject" name='subject' id="subject" className="form-control" />
                                    </div>
                                </div>

                                <div className="row form-group">
                                    <div className="col-md-12">
                                        <label className="text-black" htmlFor="message">Mensagem</label>
                                        <textarea name="message" id="message" cols="30" rows="7" className="form-control" placeholder="Escreva sua mensagem aqui..."></textarea>
                                    </div>
                                </div>

                                <div className="row form-group">
                                    <div className="col-md-12">
                                        <input type="submit" value="Enviar mensagem" className="btn btn-pill btn-primary btn-md text-white" />
                                    </div>
                                </div>
                                

                            </form>
                        </div>
                        <div className="col-md-5" data-aos="fade" data-aos-delay="100">

                            <div className="p-4 mb-3 bg-white">
                            <span class="loader"></span>
                            <p className="mb-0 font-weight-bold">Endereço</p>
                                <p className="mb-4">Rua Capitão Vicente, 134 - 4º andar - Centro | Itaúna</p>

                                <p className="mb-0 font-weight-bold">Telefone</p>
                                <p className="mb-4"><a href="tel:+553732423690">(37) 3242-3690</a></p>

                                <p className="mb-0 font-weight-bold">Whatsapp</p>
                                <p className="mb-4"><a href="https://api.whatsapp.com/send/?phone=553798712618&text&app_absent=0"> <i className="fa fa-whatsapp"></i> (37) 99871-2618 </a></p>

                                <p className="mb-0 font-weight-bold">Email</p>
                                <p className="mb-0"><a href="mailto:consultoriodrabruneledrumond@yahoo.com.br">consultoriodrabruneledrumond@yahoo.com.br</a></p>

                            </div>

                            

                        </div>
                    </div>
                </div>
            </div>
            
                
                    
                        <iframe className='col-12 framemap'
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3747.4575551484018!2d-44.580960588234305!3d-20.0731512427063!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xa732c4d4c729fd%3A0x31891d8fa1e975fe!2sR.%20Cap.%20Vicente%2C%20134%20-%204%C2%BA%20Andar%20-%20Centro%2C%20Ita%C3%BAna%20-%20MG%2C%2035680-056!5e0!3m2!1sen!2sbr!4v1689881367749!5m2!1sen!2sbr"
                            allowFullScreen="" loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"></iframe>
                        
                
            
            <Footer />

        </>

    )
}
export default Contato;
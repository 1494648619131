import React from 'react';
import Footer from '../../componentes/rodape/';
import { Helmet } from 'react-helmet';

function Sobre() {
  return (
    <>
      <Helmet>
        <title>Dra Brunele Drumond - Sobre | CRMMG 61323 Marque sua Consulta. Doenças de Pele | Cosmiatria e Estética | Tricologia Médica </title>
        
        <meta name="description" content="Dra Brunele Drumond | CRMMG 61323 Marque sua Consulta - Cuido da sua pele e dos seus cabelos com ética, Naturalidade e Ciência. O Aperfeiçoamento do Dom Nunca Para. Medicina, Primariamente, se Dedica ao Cuidado Humanizado do Paciente que me Busca, Sempre Tendo em Mente: Curar Quando Possível; Aliviar Quando Necessário; Consolar Sempre" />
        <meta name="keywords" content="dermatologia, botox, ultraformer 3, preenchimento labial, preenchimento, tricologia, harmonização facial" />
      </Helmet>
      <header className="site-navbar site-navbar-target" role="banner">
                <div className="header-area">
                    <div id="sticky-header" className="main-header-area">
                        <div className="container">
                            <div className="row align-items-center position-relative">

                                <div className="col-3">
                                    <div className="site-logo">
                                        <a href="/"><img src="./images/Brunele.ico" alt="" /></a>
                                    </div>
                                </div>

                                <div className="col-9 text-right main-menu">

                                    <span className="d-inline-block d-lg-none"><a href="#" className=" site-menu-toggle js-menu-toggle py-5">
                                        <span className="icon-menu text-white"></span></a></span>

                                    <nav className="site-navigation position-relative text-right" role="navigation">

                                        <ul className="site-menu js-clone-nav mr-auto d-none d-lg-block">
                                            <li className=""><a href="https://www.drabruneledrumond.com.br"><span>Home</span></a></li>
                                            <li className=''><a href="https://www.drabruneledrumond.com.br/tratamentos"><span>Tratamentos</span></a></li>
                                            <li className='active'><a href="https://www.drabruneledrumond.com.br/sobre"><span>Sobre</span></a></li>
                                            <li className=''><a href="https://www.drabruneledrumond.com.br/contato"><span>Contato</span></a></li>
                                        </ul>
                                    </nav>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>

            </header>

      <div className="site-blocks-cover top overlay" data-aos="fade" data-stellar-background-ratio="0.5">
        <div className="container">
          <div className="row align-items-center justify-content-center text-center">

            <div className="col-md-10">
              <span id='aparece'>Brunele Drumond</span>
              <div className="row justify-content-center mb-4">
                <div className="col-md-10 text-center">
                  <h1 data-aos="fade-up" className="mb-5">Dra.   <span className="typed-words"></span></h1>


                </div>
              </div>

            </div>
          </div>
        </div>
      </div>





      <div className="site-section">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <img src="images/sobrea.webp" alt="brunele drumond" className="img-fluid rounded mb-3" />
              <img src="images/consultorio.webp" alt="dra brunele drumond" className="img-fluid rounded mb-3" />
              <img src="images/consultorio2.webp" alt="dra brunele drumond" className="img-fluid rounded" />
            </div>
            <div className="col-md-5 ml-auto">
              <h2 className="text-primary mb-3">Sobre Mim</h2>
              <p className="lead">Me formei em medicina em 2013 pela Universidade de Itaúna. Iniciei minha carreira na área da clínica geral, atuei anos na medicina de urgência e emergência - e mesmo durante a especialização de dermatologia, eu trabalhava ainda como generalista. 

Mas... A área da medicina estética sempre me interessou e cativou. No último ano de faculdade, inclusive, tinha certeza que iria escolher a cirurgia plástica, até que conheci a dermatologia e me apaixonei.
A pele, o maior órgão do corpo humano, me conquistou com seus diagnósticos desafiadores, doenças estigmatizantes e o impacto das patologias na autoestima dos pacientes. 

Realizei minha formação na dermatologia durante 3 anos através da Faculdade Ciências Médicas de Minas Gerais FCMMG e do Hospital da Baleia. No último ano, ainda tive a oportunidade de ser monitora do ambulatório de cirurgia dermatológica do Hospital da Baleia e do ambulatório de dermatologia avançada deste mesmo serviço. Foram anos maravilhosos de conhecimento, trocas e aprendizado.

Fui dermatologista atendente em diversas clínicas, até me dedicar 100% do tempo na prática de consultório particular, há 5 anos. 

O aperfeiçoamento do dom nunca para. Medicina, primariamente, se dedica ao cuidado humanizado do paciente que me busca, sempre tendo em mente: “Curar quando possível; aliviar quando necessário; consolar sempre"</p>



              <ul className="ul-check list-unstyled success">
              <li> CRMMG 61323</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>

  )
}
export default Sobre;
import React from "react";

import Footer from "../../componentes/rodape";
import Instafeed from "../../componentes/instafeed";

function Newhome() {
    return (
        <>





            <header className="site-navbar site-navbar-target" role="banner">
                <div className="header-area">
                    <div id="sticky-header" className="main-header-area">
                        <div className="container">
                            <div className="row align-items-center position-relative">

                                <div className="col-3">
                                    
                                </div>

                                <div className="col-9 text-right main-menu">

                                    <span className="d-inline-block d-lg-none"><a href="#" className=" site-menu-toggle js-menu-toggle py-5">
                                        <span className="icon-menu text-white"></span></a></span>

                                    <nav className="site-navigation position-relative text-right" role="navigation">

                                        <ul className="site-menu js-clone-nav mr-auto d-none d-lg-block">
                                            <li className="active"><a href="https://www.drabruneledrumond.com.br"><span>Home</span></a></li>
                                            <li className=''><a href="https://www.drabruneledrumond.com.br/tratamentos"><span>Tratamentos</span></a></li>
                                            <li className=''><a href="https://www.drabruneledrumond.com.br/sobre"><span>Sobre</span></a></li>
                                            <li className=''><a href="https://www.drabruneledrumond.com.br/contato"><span>Contato</span></a></li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </header>




            <div className="site-blocks-cover overlay fundoinicio" data-aos="fade"
                data-stellar-background-ratio="0.5">
                <div className="container">
                    <div className="row align-items-center justify-content-center text-center">

                        <div className="col-md-10">
                        <span id='aparece' >home</span>

                            <div className="row justify-content-center mb-4">
                                <div className="col-md-10 text-center logo-lado">
                                    <img data-aos="fade-up" className="mb-5 tamlogo box-select" src="./images/Brunele.png" alt="Dermatologista" /><p data-aos="fade-up" data-aos-delay="100"><a href="https://www.drabruneledrumond.com.br/contato" className="btn btn-primary btn-pill">Agende uma Consulta</a></p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>



            <div className="block-quick-info-2">
                <div className="container">
                    <div className="block-quick-info-2-inner">
                        <div className="row">
                            <div className="col-sm-6 col-md-6 col-lg-3 mb-3 mb-lg-0">
                                <div className="d-flex quick-info-2">
                                    <span className="icon icon-home mr-3"></span>
                                    <div className="text">
                                        <strong className="d-block heading">Visite a Clínica</strong>
                                        <span className="excerpt">R. Capitão Vicente, 134</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-3 mb-3 mb-lg-0">
                                <div className="d-flex quick-info-2">
                                    <span className="icon icon-phone mr-3"></span>
                                    <div className="text">
                                        <strong className="d-block heading">Agende uma consulta</strong>
                                        <span className="excerpt"><a href="https://api.whatsapp.com/send/?phone=553798712618&text&app_absent=0">(37) 99871-2618</a></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-3 mb-3 mb-lg-0">
                                <div className="d-flex quick-info-2">
                                    <span className="icon icon-clock-o mr-3"></span>
                                    <div className="text">
                                        <strong className="d-block heading">Horário</strong>
                                        <span className="excerpt">Seg-Sex 8h às 17h</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-3 mb-3 mb-lg-0">
                                <div className="d-flex quick-info-2">
                                    <span className="icon icon-envelope mr-3"></span>
                                    <div className="text">
                                        <strong className="d-block heading">Mande uma mensagem</strong>
                                        <span className="excerpt"><a className="fontemail" href="mailto:consultoriodrabruneledrumond@yahoo.com.br">Email Dra. Brunele</a></span>
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>

            <div className="block-services-1 py-5">
                <div className="container">
                    <div className="row">
                        <div className="mb-4 mb-lg-0 col-sm-6 col-md-6 col-lg-3 box-select">
                            <h3 className="mb-3"><a href="https://www.drabruneledrumond.com.br/tratamentos">Tratamentos</a></h3>
                            <p>O profissional especializado realiza diagnósticos e previne, bem como indica, os melhores tratamentos - para, então, garantir ao paciente o bem-estar e uma vida saudável.</p>
                            <p><a href="https://www.drabruneledrumond.com.br/tratamentos" className="d-inline-flex align-items-center block-service-1-more"><span>Ver todos tratamentos</span>
                                <span className="icon-keyboard_arrow_right icon"></span></a></p>
                        </div>
                        <div className="mb-4 mb-lg-0 col-sm-6 col-md-6 col-lg-3">
                            <div className="block-service-1-card">
                                <a href="https://www.drabruneledrumond.com.br/tratamentos" className="thumbnail-link d-block mb-4"><img src="images/doenca.webp" alt="médico doenças de pele"
                                    className="img-fluid" /></a>
                                <h3 className="block-service-1-heading mb-3"><a href="https://www.drabruneledrumond.com.br/tratamentos">Doenças de pele</a></h3>
                                <div className="block-service-1-excerpt">
                                    <p>Os focos principais são doenças que atrapalham a rotina como, por exemplo, a acne. Além disso, diversos tipos de alergias da pele, da parte capilar, foliculite, câncer de pele, herpes, hiperidrose, machas, entre outros.</p>
                                </div>
                                <p><a href="https://www.drabruneledrumond.com.br/tratamentos" className="d-inline-flex align-items-center block-service-1-more"><span>Saiba mais</span>
                                    <span className="icon-keyboard_arrow_right icon"></span></a></p>
                            </div>
                        </div>
                        <div className="mb-4 mb-lg-0 col-sm-6 col-md-6 col-lg-3">
                            <div className="block-service-1-card">
                                <a href="https://www.drabruneledrumond.com.br/tratamentos" className="thumbnail-link d-block mb-4"><img src="images/estetica.webp" alt="preenchimento labial"
                                    className="img-fluid" /></a>
                                <h3 className="block-service-1-heading mb-3"><a href="https://www.drabruneledrumond.com.br/tratamentos">Cosmiatria e estética</a></h3>
                                <div className="block-service-1-excerpt">
                                    <p>Beleza da pele, cabelos e unhas. Com tecnologia avançada, conseguimos prevenir o envelhecimento cutâneo, melhorando e elevando a autoestima.</p>
                                </div>
                                <p><a href="https://www.drabruneledrumond.com.br/tratamentos" className="d-inline-flex align-items-center block-service-1-more"><span>Saiba mais</span>
                                    <span className="icon-keyboard_arrow_right icon"></span></a></p>
                            </div>
                        </div>
                        <div className="mb-4 mb-lg-0 col-sm-6 col-md-6 col-lg-3">
                            <div className="block-service-1-card">
                                <a href="https://www.drabruneledrumond.com.br/tratamentos" className="thumbnail-link d-block mb-4"><img src="images/fototric.webp" alt="tratamento do couro cabeludo"
                                    className="img-fluid" /></a>
                                <h3 className="block-service-1-heading mb-3"><a href="#">Tricologia médica</a></h3>
                                <div className="block-service-1-excerpt">
                                    <p>Consulta e tratamento Capilar e do couro cabeludo </p>
                                </div>
                                <p><a href="https://www.drabruneledrumond.com.br/tratamentos" className="d-inline-flex align-items-center block-service-1-more"><span>Saiba mais</span>
                                    <span className="icon-keyboard_arrow_right icon"></span></a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="block-half-content-1 d-block d-lg-flex mt-5">
                <div className="block-half-content-img fundomeio" >

                </div>
                <div className="block-half-content-text bg-primary">
                    <div className="block-half-content-text-inner">
                        <h2 className="block-half-content-heading mb-4">Dra. Brunele Drumond</h2>
                        <div className="block-half-content-excerpt">
                            <p className="lead">A minha paixão é te ajudar a alcançar a sua melhor versão. Acredite no processo: com disciplina e paciência chegamos lá. Conte comigo na jornada!</p>
                        </div>
                    </div>

                    <div className="block-counter-1 section-counter">
                        <div className="row">
                            <div className="col-sm-4">
                                <div className="counter">
                                    <div className="number-wrap">
                                        <span className="block-counter-1-number" data-number="3">0</span><span className="append">K</span>
                                    </div>
                                    <span className="block-counter-1-caption">Pacientes Atendidos</span>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="counter">
                                    <div className="number-wrap">
                                        <span className="block-counter-1-number" data-number="7">0</span><span className="append"></span>
                                    </div>
                                    <span className="block-counter-1-caption">Anos de Experiência</span>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="counter">
                                    <div className="number-wrap">
                                        <span className="block-counter-1-number" data-number="100">0</span><span className="append">%</span>
                                    </div>
                                    <span className="block-counter-1-caption">Satisfação</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="site-section bg-light">
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-md-12 text-center">
                            <h2 className="site-section-heading text-center font-secondary">Tratamentos</h2>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="d-block d-lg-flex">
                        <div className="half-wrap d-block d-md-flex">
                            <div className="half bg-white d-block d-md-flex arrow-right">
                                <div className="text">
                                    <h2>Harmonização Facial</h2>
                                    <p>Harmonizar, embelezar, ressaltar atributos e tratar assimetrias.</p>
                                    <p><a href="https://www.drabruneledrumond.com.br/tratamentos" className="btn btn-primary btn-sm btn-pill">Saiba mais</a></p>
                                </div>
                            </div>
                            <div className="half bg-img img fundom1" ></div>
                        </div>
                        <div className="half-wrap d-block d-md-flex">
                            <div className="half bg-white d-block d-md-flex arrow-right">
                                <div className="text">
                                    <h2>Preenchimento Labial</h2>
                                    <p>Delinear, ou realçar o contorno da boca e/ou dar volume aos lábios.</p>
                                    <p><a href="https://www.drabruneledrumond.com.br/tratamentos" className="btn btn-primary btn-sm btn-pill">Saiba mais</a></p>
                                </div>
                            </div>
                            <div className="half bg-img img fundom2" ></div>
                        </div>
                    </div>

                    <div className="d-block d-lg-flex">
                        <div className="half-wrap d-block d-md-flex">
                            <div className="half bg-white d-block d-md-flex arrow-left order-md-2">
                                <div className="text">
                                    <h2>Dermatologia</h2>
                                    <p>Manter a pele saudável, bem cuidada e reduzir riscos de doenças.</p>
                                    <p><a href="https://www.drabruneledrumond.com.br/tratamentos" className="btn btn-primary btn-sm btn-pill">Saiba mais</a></p>
                                </div>
                            </div>
                            <div className="half bg-img img fundom3" ></div>
                        </div>
                        <div className="half-wrap d-block d-md-flex">
                            <div className="half bg-white  d-block d-md-flex arrow-left order-md-2">
                                <div className="text">
                                    <h2>Ultraformer III</h2>
                                    <p>Procedimentos estéticos com nova geração de ultrassom de lifting.</p>
                                    <p><a href="https://www.drabruneledrumond.com.br/tratamentos" className="btn btn-primary btn-sm btn-pill">Saiba mais</a></p>
                                </div>
                            </div>
                            <div className="half bg-img img fundom4" ></div>
                        </div>
                    </div>


                </div>
            </div>

            <Instafeed />







            <Footer />
            <a href="https://api.whatsapp.com/send/?phone=553798712618&text&app_absent=0" className="btn-whats"><img alt="whatsapp icone" src="./images/whatsapp-icon-square.svg" itemprop="image" /></a>

        </>

    )
}

export default Newhome;
import React from 'react';

function Footer() {
  return (
    <>
      <div className="mt-5 block-cta-1 primary-overlay fundofooter" >
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-lg-7 mb-4 mb-lg-0">
              <h2 className="mb-3 mt-0 text-white">Agende uma consulta!</h2>
              <p className="mb-0 text-white lead">Te ajudo a alcançar sua melhor versão. Cuidando da sua pele e dos seus cabelos.</p>
            </div>
            <div className="col-lg-4">
              <p className="mb-0"><a href="https://www.drabruneledrumond.com.br/contato" className="btn btn-outline-white text-white btn-md btn-pill px-5 font-weight-bold btn-block">Contato</a></p>
            </div>
          </div>
        </div>
      </div>



      <footer className="site-footer py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-9">
              <div className="row">
                <div className="col-md-6 mb-5 mb-lg-0 col-lg-3">
                  <h2 className="footer-heading mb-4">Tratamentos</h2>
                  <ul className="list-unstyled">
                  <li><a href="https://www.drabruneledrumond.com.br/tratamentos">Tricologia</a></li>
                  <li><a href="https://www.drabruneledrumond.com.br/tratamentos">Ultraformer</a></li>
                    <li><a href="https://www.drabruneledrumond.com.br/tratamentos">Dermatologia</a></li>
                    <li><a href="https://www.drabruneledrumond.com.br/tratamentos">Toxina Botulínica</a></li>
                  </ul>
                </div>
                <div className="col-md-6 mb-5 mb-lg-0 col-lg-3">
                  <h2 className="footer-heading mb-4">Tratamentos</h2>
                  <ul className="list-unstyled">
                    <li><a href="https://www.drabruneledrumond.com.br/tratamentos">Bioestimuladores</a></li>
                    <li><a href="https://www.drabruneledrumond.com.br/tratamentos">Preenchimentos</a></li>
                    <li><a href="https://www.drabruneledrumond.com.br/tratamentos">Harmonização</a></li>
                    <li><a href="https://www.drabruneledrumond.com.br/tratamentos">Doenças de pele</a></li>
                  </ul>
                </div>
                <div className="col-md-6 mb-5 mb-lg-0 col-lg-3">
                  <h2 className="footer-heading mb-4">Links</h2>
                  <ul className="list-unstyled">
                    <li><a href="https://www.drabruneledrumond.com.br">Home</a></li>
                    <li><a href="https://www.drabruneledrumond.com.br/sobre">Sobre</a></li>
                    <li><a href="https://www.drabruneledrumond.com.br/contato">Contato</a></li>
                    <li><a href="https://www.drabruneledrumond.com.br/tratamentos">Tratamentos</a></li>
                  </ul>
                </div>
                
                <div className="col-md-6 mb-5 mb-lg-0 col-lg-3">
                  <h2 className="footer-heading mb-4">Links sociais</h2>
                  <a href="https://www.facebook.com/Brunele-Drumond-335838650477875" className="pl-0 pr-3"><span className="icon-facebook"></span></a>
                  <a href="https://www.instagram.com/drabruneledrumond/" className="pl-3 pr-3"><span className="icon-instagram"></span></a>
                  <a href="https://api.whatsapp.com/send/?phone=553798712618&text&app_absent=0"> <i className="fa fa-whatsapp"></i></a>
                  
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <h2 className="footer-heading mb-4">Contato</h2>
              <a href="mailto:consultoriodrabruneledrumond@yahoo.com.br"><span className="icon icon-envelope mr-3"></span>Email</a><br />
              <a href="tel:+553732423690"> <span className="icon-phone"></span>    (37) 3242-3690</a><br />
              <a href="https://api.whatsapp.com/send/?phone=553798712618&text&app_absent=0"> <i className="fa fa-whatsapp"></i>    (37) 99871-2618 </a>
            </div>
          </div>
          <div className="row pt-5 mt-5 ">
            <div className="col-12">
              <p className='text-center'>

                Copyright &copy;<script>document.write(new Date().getFullYear());</script> Feito por <i className="icon-heart text-danger" aria-hidden="true"></i> <a href="https://www.instagram.com/dot.midias/" target="_blank" >DotMidias</a>

              </p>
            </div>


          </div>
        </div>
        
      </footer>
    </>

  )
}
export default Footer;
import React from "react";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Tratamentos from "./view/tratamentos/";
import Contato from "./view/contato/";
import Sobre from "./view/sobre";
import Newhome from "./view/home";






import { HelmetProvider } from 'react-helmet-async';


function App() {
  return (
    <HelmetProvider>
      <Switch>
        <Router>
          
          <Route exact path='/tratamentos' component={Tratamentos} />
          <Route exact path='/contato' component={Contato} />
          <Route exact path='/sobre' component={Sobre} />
          <Route exact path='/' component={Newhome} />
          
        </Router>
      </Switch>
    </HelmetProvider>

  );
}

export default App;


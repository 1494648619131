import React from 'react';

function Instafeed() {
    return (
        <>
            <div className="site-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 text-center mx-auto">
                            <span className="text-primary d-block">@drabruneledrumond</span>
                            <h2 className="site-section-heading text-center font-secondary ">Siga no Instagram</h2>

                        </div>
                    </div>
                    <div className="row pt-3">
                        <div className="col-lg-4">
                            <div id="local1" className="post-entry-1 h-100">

                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div id="local2" className="post-entry-1 h-100">

                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div id="local3" className="post-entry-1 h-100">

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        



        </>

    )
}

export default Instafeed;